.bodyCareer {
  padding: 69px 55px 69px 55px;
  display: flex;
  flex-direction: column;
  background-color: #f8f8f9;

  .container {
    display: flex;
    gap: 40px;

    .mobileContainer {
      display: none;
    }

    .cardContainer {
      display: flex;
      flex-direction: column;
      gap: 20px;
      flex: 8;

      .cards {
        display: flex;
        gap: 16px;
        width: 100%;
        justify-content: end;
      }
    }

    .imagesBox {
      width: 100%;
      img {
        width: 100%;
        height: 100%;
      }
    }

    .descBox {
      display: flex;
      flex: 4;
      flex-direction: column;
      justify-content: space-between;

      .information {
        display: flex;
        flex-direction: column;
      }

      .imageWrapper {
        display: flex;
        position: relative;
        align-items: center;
        justify-content: center;

        .blueBoxesWrapper {
          display: flex;
          flex-direction: column;
          position: absolute;
          width: 100%;
          height: 100%;
          z-index: 1;

          .topBox {
            display: flex;
            background-color: #87a1c3;
            height: 100%;
            width: 50%;
            align-self: flex-end;
          }

          .bottomBox {
            display: flex;
            background-color: #2b4d7b;
            height: 100%;
            width: 50%;
          }
        }

        .img {
          width: 100%;
          // height: fit-content;
          margin: 16px;
          z-index: 2;
        }
      }

      .download {
        display: flex;
        flex-direction: column;
        gap: 16px;
        .aplicationBox {
          display: flex;
          align-items: center;
          gap: 16px;
          .google {
            width: 25%;
            img {
              width: 100%;
              height: 100%;
            }
          }
        }
        .seeJobs {
          cursor: pointer;
          color: #12bce5;

          /* Subtitle/2 */
          //   font-family: Poppins;
          font-size: calc(14px + (18 - 14) * ((100vw - 280px) / (1600 - 280)));
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }
      }
      .p {
        color: var(--primary-60, #142f58);
        // font-family: Poppins;
        // font-size: calc(14px + (16 - 14) * ((100vw - 280px) / (1600 - 280)));
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 150% */
      }
      .H1 {
        color: var(--primary-60, #142f58);

        /* Headings/H1 */
        // font-family: Poppins;
        // font-size: calc(16px + (40 - 16) * ((100vw - 280px) / (1600 - 280)));
        font-size: 40px;
        font-style: normal;
        font-weight: 700;
        // line-height: 46px; /* 115% */
      }
    }
  }
}

// @media (max-width: 1024px) {
//   .bodyCareer {
//     // padding: 100px 24px;
//     .container {
//       padding-left: 0px;
//       padding-right: 0px;
//     }
//   }
// }

@media (max-width: 1228px) {
  .bodyCareer {
    padding: 100px 24px 100px 24px;
    .container {
      gap: 24px;

      .descBox {
        .imageWrapper {
          display: none;
        }
      }
      // padding-left: 0px;
      // padding-right: 0px;
    }
  }
}

@media (max-width: 1040px) {
  .bodyCareer {
    padding: 69px 0px 69px 0px;
    .container {
      flex-direction: column;

      .mobileContainer {
        flex-direction: column;
        gap: 24px;
        width: 100%;
        display: flex;

        .cards {
          height: auto;
          display: grid;
          grid-auto-flow: column;
          gap: 16px;
          width: 100%;
          scroll-behavior: auto;
          overflow-y: auto;
          overscroll-behavior-x: contain;
          scrollbar-width: none;
          scroll-snap-type: x mandatory;
        }
      }

      .cardContainer {
        display: none;
      }

      .descBox {
        padding: 0px 32px 0px 32px;
        gap: 24px;

        .imageWrapper {
          display: flex;
        }

        .H1 {
          font-size: 32px;
        }

        .p {
          font-size: 18px;
        }
      }
    }
  }
}

// @media (max-width: 912px) {
//   .bodyCareer {
//     padding: 100px 24px 100px 24px;
//     .container {
//       gap: 24px;
//       // padding-left: 0px;
//       // padding-right: 0px;
//     }
//   }
// }

// @media (max-width: 650px) {
//   .bodyCareer {
//     //   padding: 100px 24px;

//     .container {
//       flex-direction: column;
//       gap: 24px;
//       width: 100%;

//       .cardContainer {
//         display: none;
//         width: 0px;
//         flex-direction: row;
//         gap: 16px;
//       }

//       .imagesBox {
//         width: 80%;
//       }

//       //   padding-left: 0px;
//       //   padding-right: 0px;
//     }
//   }
// }
