$BackgroundBenefit: url("../../assets/png/BenefitLayout/BackgroundBenefit.png");
// $IndonesiaGlobe: url("../../assets/png/OurCompany/IndonesiaGlobe.png");
// $mapicon: url("../../assets/png/OurCompany/mapicon.png");

.Container {
  width: 100%;
  // width: 100vw;
  // height: 559px;
  height: 100%;
  display: flex;
  flex-direction: column;
  // background: #FFF;
  background-image: $BackgroundBenefit;
  background-repeat: no-repeat;
  background-size: cover;

  background-position: center;
  padding: 128px 0px;
  gap: 32px;
  .titleBenefit {
    display: flex;
    flex-direction: column;
    display: flex;
    padding: 0px 55px;
    .H1 {
      color: var(--primary-60, #142f58);
      // font-family: Poppins;
      font-size: calc(14px + (40 - 14) * ((100vw - 280px) / (1600 - 280)));
      font-style: normal;
      font-weight: 600;
      line-height: 56px; /* 140% */
    }
    .p {
      color: var(--neutral-70, #6c757d);
      // font-family: Poppins;
      font-size: calc(14px + (14 - 14) * ((100vw - 280px) / (1600 - 280)));
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 142.857% */
      width: 40%;
    }
  }
  .boxBenefit {
    display: flex;
    width: 100vw;
    .firstBox {
      display: flex;
      padding: 32px 72px;
      flex-direction: column;
      gap: 24px;
      background: #f0f0f0;
      width: 100%;
      img {
        width: 48px;
        height: 48px;
      }
      .list {
        display: flex;
        flex-direction: column;
        // gap: 4px;
        .provider {
          display: flex;
          align-items: center;
          gap: 16px;
          .text {
            color: #494646;
            // font-family: Poppins;
            font-size: calc(
              15px + (13 - 14) * ((100vw - 280px) / (1600 - 280))
            );
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
          .logo {
            color: #333;
            font-weight: 900;
            font-size: calc(
              14px + (24 - 14) * ((100vw - 280px) / (1600 - 280))
            );
            position: relative;
            bottom: 5px;
          }
        }
        .forProvider {
          color: #333;
          // font-family: Poppins;
          font-size: calc(16px + (16 - 14) * ((100vw - 280px) / (1600 - 280)));
          font-style: normal;
          font-weight: 500;
          // line-height: 130%; /* 20.8px */
        }
      }
    }

    .secondBox {
      display: flex;
      padding: 32px 72px;
      flex-direction: column;
      gap: 24px;
      background: #e8e8e8;
      width: 100%;
      img {
        width: 48px;
        height: 48px;
      }
      .list {
        display: flex;
        flex-direction: column;
        // gap: 4px;
        .provider {
          display: flex;
          align-items: center;
          gap: 16px;
          .text {
            color: #494646;
            // font-family: Poppins;
            font-size: calc(
              15px + (13 - 14) * ((100vw - 280px) / (1600 - 280))
            );
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
          .logo {
            color: #333;
            font-weight: 900;
            font-size: calc(
              14px + (24 - 14) * ((100vw - 280px) / (1600 - 280))
            );
            position: relative;
            bottom: 5px;
          }
        }
        .forProvider {
          color: #333;
          // font-family: Poppins;
          font-size: calc(16px + (16 - 14) * ((100vw - 280px) / (1600 - 280)));
          font-style: normal;
          font-weight: 500;
          // line-height: 130%; /* 20.8px */
        }
      }
    }

    .thirdBox {
      display: flex;
      padding: 32px 72px;
      flex-direction: column;
      gap: 24px;
      background: #e0e0e0;
      width: 100%;
      img {
        width: 48px;
        height: 48px;
      }
      .list {
        display: flex;
        flex-direction: column;
        // gap: 4px;
        .provider {
          display: flex;
          align-items: center;
          gap: 16px;
          .text {
            color: #494646;
            // font-family: Poppins;
            font-size: calc(
              15px + (13 - 14) * ((100vw - 280px) / (1600 - 280))
            );
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
          .logo {
            color: #333;
            font-weight: 900;
            font-size: calc(
              14px + (24 - 14) * ((100vw - 280px) / (1600 - 280))
            );
            position: relative;
            bottom: 5px;
          }
        }
        .forProvider {
          color: #333;
          // font-family: Poppins;
          font-size: calc(16px + (16 - 14) * ((100vw - 280px) / (1600 - 280)));
          font-style: normal;
          font-weight: 500;
          // line-height: 130%; /* 20.8px */
        }
      }
    }

    // thirdBox
  }

  .dropdownBox {
    display: none;
  }
}

@media (max-width: 1000px) {
  .Container {
    .titleBenefit {
      padding: 0px 24px;
    }
  }
}

@media (max-width: 820px) {
  .Container {
    padding: 32px 24px;

    .dropdownBox {
      display: flex;
      flex-direction: column;
      // padding-bottom: 50px;
      .firstBox {
        // padding: 32px;
        background: #f0f0f0;
        display: flex;
        padding: 24px;
        flex-direction: column;
        gap: 8px;
        .provider {
          display: flex;
          align-items: center;
          gap: 16px;
          .logo {
            color: #333;
            font-weight: 900;
            font-size: calc(
              14px + (24 - 14) * ((100vw - 280px) / (1600 - 280))
            );
            position: relative;
            bottom: 5px;
          }
          .text {
            color: #494646;
            // font-family: Poppins;
            font-size: calc(
              14px + (13 - 14) * ((100vw - 280px) / (1600 - 280))
            );
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }
      }

      .secondBox {
        // padding: 32px;
        background: #e8e8e8;
        display: flex;
        padding: 24px;
        flex-direction: column;
        gap: 8px;
        .provider {
          display: flex;
          align-items: center;
          gap: 16px;
          .logo {
            color: #333;
            font-weight: 900;
            font-size: calc(
              14px + (24 - 14) * ((100vw - 280px) / (1600 - 280))
            );
            position: relative;
            bottom: 5px;
          }
          .text {
            color: #494646;
            // font-family: Poppins;
            font-size: calc(
              14px + (13 - 14) * ((100vw - 280px) / (1600 - 280))
            );
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }
      }

      .thirdBox {
        // padding: 32px;
        background: #e0e0e0;
        display: flex;
        padding: 24px;
        flex-direction: column;
        gap: 8px;
        .provider {
          display: flex;
          align-items: center;
          gap: 16px;
          .logo {
            color: #333;
            font-weight: 900;
            font-size: calc(
              14px + (24 - 14) * ((100vw - 280px) / (1600 - 280))
            );
            position: relative;
            bottom: 5px;
          }
          .text {
            color: #494646;
            // font-family: Poppins;
            font-size: calc(
              14px + (13 - 14) * ((100vw - 280px) / (1600 - 280))
            );
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }
      }
      .listDropdown {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 16px;
        border-bottom: 1px solid var(--neutral-40, #dee2e6);
        .textDropdown {
          color: var(--neutral-90, #2f3439);
          font-size: calc(14px + (18 - 14) * ((100vw - 280px) / (1600 - 280)));
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }
        img {
          width: 12px;
          height: 12px;
          cursor: pointer;
        }
      }
    }

    .titleBenefit {
      padding: 0px;
      .p {
        width: 100%;
      }
    }

    .boxBenefit {
      display: none;
    }
  }
}
