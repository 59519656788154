@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root {
  font-size: 14px;
  a, h1, h2, h3, h4, h5, p, span {
    font-family: 'Poppins', sans-serif;
  }
  @media (max-width: 1200px) {
    font-size: 12px;
  }
  @media (max-width: 1000px) {
    font-size: 10px;
  }
  @media (max-width: 800px) {
    font-size: 10px;
  }
  @media (max-width: 666px) {
    font-size: 10px;
  }
  @media (max-width: 400px) {
    font-size: 10px;
  }
}