.Container {
  width: 100%;
  padding: 0px 160px;
  .boxFeature {
    display: flex;
    gap: 8px;
    // justify-content: flex-end;
    .box {
      border-radius: 8px;
      border: 1px solid var(--neutral-10, #fff);
      background: rgba(255, 255, 255, 0.1);
      backdrop-filter: blur(8px);
      display: flex;
      flex-direction: column;
      gap: 16px;
      padding: 16px;
      transition: 500ms;
      // width: 200px;
      // height: 150px;
      // height: 100%;
      width: 100%;
      cursor: pointer;
      .textBox {
        display: flex;
        flex-direction: column;
        gap: 4px;
        .title {
          color: #fff;
          // font-family: Poppins;
          font-size: calc(8px + (14 - 8) * ((100vw - 280px) / (1600 - 280)));
          font-style: normal;
          font-weight: 600;
          // line-height: 20px; /* 142.857% */
          // width: 50%;
          // padding-right: 20px;
        }
        .desc {
          color: var(--neutral-50, #ced4da);
          // font-family: Poppins;
          font-size: calc(10px + (12 - 10) * ((100vw - 280px) / (1600 - 280)));
          font-style: normal;
          font-weight: 400;
          line-height: 16px; /* 133.333% */
          // width: 100;
        }
      }
      .images {
        // width: 20%;

        img {
          width: 32px;
          height: 32px;
        }
      }
    }

    .box:hover {
      background-color: #011e4b;
      border-color: #011e4b;
      margin-top: -20px;
      padding-top: 20px;
      transition: 500ms;
    }
  }

  @media (max-width: 1310px) {
    padding: 0px 56px;
  }

  @media (max-width: 1000px) {
    padding: 0px 24px;
    .boxFeature {
      .box {
        padding-left: 10px;
      }
    }
  }

  @media (max-width: 570px) {
    padding: 0px 0px;
    .boxFeature {
      // flex-wrap: wrap;
      gap: 8px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      // width: auto;
      // right: 50%;
      // transform: translateX(50%) translateY(50%);
      .box {
        // width: 43%;
        padding: 10px;

        // bottom: 45%;
        .textBox {
          .title {
            font-size: 14px;
          }
          .desc {
            font-size: 12px;
          }
        }
        .images {
          width: 100%;
          img {
            width: 32px;
            height: 32px;
          }
        }
      }
      .box:hover {
        background-color: #011e4b;
        border-color: #011e4b;
        margin-top: 0px;
        // padding-top: 0px;
      }
    }
  }

  @media (max-width: 325px) {
    .boxFeature {
      // gap: 4px;

      // transform: translateX(50%) translateY(50%);
      .box {
        // width: 100px;
        height: 105px;
        // height: 155px;
        // // width: 100%;
        // width: 43%;
        // padding: 16px;

        // bottom: 45%;
        .textBox {
          .title {
            font-size: 8px;
          }
          .desc {
            font-size: 8px;
          }
        }
        .images {
          width: 100%;
          img {
            width: 20px;
            height: 20px;
          }
        }
      }
    }
  }
}
