.Container {
  width: 100%;
  height: 100%;
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;

  .tesWa {
    background-color: #214275;
    position: fixed;
    bottom: 48px;
    right: 24px;
    z-index: 3;
    cursor: pointer;
    transition: 200ms;
    border-radius: 60px;
    display: flex;
    gap: 10px;
    align-items: center;
    padding: 6px 16px 6px 6px;
    -webkit-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.75);
    text-decoration: none;

    .LogoWrapper {
      height: 72px;
      width: 72px;
      border-radius: 50%;
      // background-color: salmon;
      background-image: linear-gradient(
        rgba(45, 115, 210, 1),
        rgba(73, 133, 212, 0)
      );
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        height: 40px;
        width: 40px;
      }
    }

    span {
      color: #fff;
      font-size: 18px;
      font-weight: 600;
    }
  }

  .tesWa:hover {
    background-color: #142f58;
    transition: 200ms;
  }
}
body::-webkit-scrollbar {
  display: none;
}
.banner {
  height: 100vh;
}

@media (max-width: 1000px) {
  .Container {
    .tesWa {
      right: 12px;

      .LogoWrapper {
        height: 48px;
        width: 48px;

        img {
          height: 28px;
          width: 28px;
        }
      }
    }
  }
}
