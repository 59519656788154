.container {
  width: 242px;

  .top {
    background-color: #fff;
    border: 1px solid #e9ecef;
    border-radius: 8px 8px 0px 0px;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 16px 20px;
    justify-content: space-between;
    gap: 24px;

    .content {
      display: flex;
      flex-direction: column;
      gap: 8px;

      .title {
        font-size: 18px;
        font-weight: 600;
        color: #214275;
      }

      .text {
        font-size: 14px;
        font-weight: 400;
        color: #000;
        line-height: 21px;
      }
    }

    .logo {
      display: flex;
      justify-content: end;
    }

    .img {
      max-height: 44px;
      height: fit-content;
    }
  }

  .bottom {
    background-color: #142f58;
    border-radius: 0px 0px 8px 8px;
    height: 8px;
  }
}

@media (max-width: 1040px) {
  .container {
    display: flex;
    flex-direction: column;
  }
}
