$BackContactUs: url("../../assets/png/ContactUsLayout/BackContuctUs.png");
.Container {
  // top: 0;
  width: 100%;
  // background-color: #fff;
  .ContactUsMain {
    display: flex;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: $BackContactUs;
    width: 100%;
    height: 478px;
    // top: 0;
    text-align: center;
    padding-top: 8%;
    display: flex;
    align-items: center;
    flex-direction: column;
    // background-color: blue;
    .ContactUsText {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 440px;
      & > .ContactUsTitle {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 40px;
        // font-size: calc(14px + (40 - 14) * ((100vw - 280px) / (1600 - 280)));
        line-height: 130%;
        // text-align: center;
        color: #ffffff;
        // background-color: red;
      }
      & > .ContactUsDesc {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 135%;
        color: #ffffff;
      }
    }
  }
  .ContactText {
    padding: 32px 24px;
    flex-direction: column;
    gap: 8px;
    & > :first-child {
      font-size: calc(14px + (40 - 14) * ((100vw - 280px) / (1600 - 280)));
      font-weight: 600;
    }
    & > :last-child {
      font-size: 14px;
      font-weight: 400;
      color: #6c757d;
    }
  }
  .ContactWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    bottom: 200px;
    // margin-top: -180px;
    // margin-bottom: 100px;
    .ContactForm {
      display: flex;
      background: #ffffff;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
      border-radius: 5px;
      // left: 50%;
      flex-direction: column;
      // width: 428px;
      width: 80%;
      & > .ContactFormAtas {
        .ContactFormTitle {
          font-weight: 600;
          font-size: calc(20px + (26 - 20) * ((100vw - 280px) / (1600 - 280)));
          text-align: center;
        }
        // padding: 32px;
        padding: 32px 24px;

        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 130%;
        letter-spacing: 0.005em;
        color: #000000;
        & > .buttonBox {
          // width: 20%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          & > .KotakTombol {
            width: 20%;
            & > input[type="submit"] {
              cursor: pointer;
              margin-top: 10px;
              width: 100%;
              height: 45px;
              border-radius: 8px;
              background: var(--primary-60, #142f58);
              font-family: "Poppins";
              font-style: normal;
              font-weight: 500;
              font-size: 14px;
              line-height: 130%;
              letter-spacing: 0.005em;
              color: #ffffff;
            }
          }
        }
        & > form {
          display: flex;
          flex-direction: column;
          gap: 16px;
          padding-block: 24px;
          & > .formTop {
            display: flex;
            align-items: center;
            gap: 16px;
            & > .Kotak {
              width: 100%;
              display: flex;
              flex-direction: column;
              gap: 4px;
              & > label {
                color: #000;
                // font-family: Poppins;
                font-size: 13px;
                font-style: normal;
                font-weight: 500;
                line-height: 20px; /* 153.846% */
                letter-spacing: 0.065px;
              }
              & > input[type="text"] {
                box-sizing: border-box;
                width: 100%;
                height: 40px;
                padding: 16px;
                background: #ffffff;
                border: 1px solid #e6eaec;
                border-radius: 5px;
              }
            }
          }

          & > .Kotak {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 4px;
            & > label {
              color: #000;
              // font-family: Poppins;
              font-size: 13px;
              font-style: normal;
              font-weight: 500;
              line-height: 20px; /* 153.846% */
              letter-spacing: 0.065px;
            }
            & > textarea {
              width: 100%;
              height: 113px;
              padding: 12px;
              box-sizing: border-box;
              // border: 2px solid #ccc;
              // border-radius: 4px;
              border-radius: 8px;
              border: 1px solid var(--neutral-40, #dee2e6);
              background: var(--neutral-10, #fff);
              color: #a6a3a3;
              // font-family: Poppins;
              font-size: 13px;
              font-style: normal;
              font-weight: 400;
              line-height: 20px; /* 153.846% */
              resize: none;
            }
          }

          // & > .TextEmail {
          //   position: absolute;
          //   top: 20px;
          //   left: 580px;
          // }
          // & > .InputEmail {
          //   position: absolute;
          //   left: 580px;
          // }
          // & > .TextSubject {
          //   position: absolute;
          //   top: 140px;
          // }
          // & > .InputSubject {
          //   position: absolute;
          //   top: 160px;
          // }
          // & > .TextMessage {
          //   position: absolute;
          //   top: 140px;
          //   left: 580px;
          // }
          // & > .InputMessage {
          //   position: absolute;
          //   top: 160px;
          //   left: 580px;
          // }
        }
      }
      & > .ContactFormBawah {
        display: flex;
        gap: 8px;
        align-items: center;
        // padding-block: 17px;
        padding: 24px;
        justify-content: center;
        border-radius: 0px 0px 8px 8px;
        background: var(--primary-60, #142f58);
        .imagesBox {
          position: relative;
          top: 3px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        & > :last-child {
          font-weight: 400;
          color: #fff;
          text-align: center;
          // font-family: Poppins;
          font-size: calc(9px + (14 - 9) * ((100vw - 280px) / (1600 - 280)));
          font-style: normal;
          font-weight: 400;
          line-height: 20px; /* 142.857% */
          margin: 0;
          // width: 50%;
          .span {
            color: #edb31e;
            text-decoration: none;
            span {
              color: #fff;
            }
          }
          > span {
            color: #edb31e;
          }
        }
      }
    }
  }
  .ShowOnMobile {
    display: none;
  }
}

@media (max-width: 1024px) {
  .Container {
    .ContactWrapper {
      bottom: 265px;
    }
  }
}

@media (max-width: 500px) {
  .Container {
    .ShowOnMobile {
      display: flex;
    }
    .ContactWrapper {
      margin: 0 !important;
      position: unset;
      .ContactForm {
        width: 100% !important;
        position: static;
        transform: none !important;
        .ContactFormAtas {
          .buttonBox {
            // flex-direction: column;
            // padding-left: 16px;
            // padding-right: 16px;
            .KotakTombol {
              width: 100%;
            }
          }
          form {
            // padding-left: 16px;
            // padding-right: 16px;
            .formTop {
              flex-direction: column;
            }
          }
        }
        .ContactFormBawah {
          border-radius: 0px;

          // .imagesBox{
          //   width: 15px;
          //   height: 15px;
          // }
        }
      }
    }
    .ContactUsMain {
      height: 140px !important;
      background-size: cover !important;
      .ContactUsText {
        display: none;
      }
    }
  }
}
