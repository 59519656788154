$BackgroundOurCompany: url("../../assets/png/OurCompany/BackgroundOurCompany.png");
// $IndonesiaGlobe: url("../../assets/png/OurCompany/IndonesiaGlobe.png");
// $mapicon: url("../../assets/png/OurCompany/mapicon.png");

.Container {
  width: 100vw;
  height: 100%;
  // height: 509px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-image: $BackgroundOurCompany;
  background-size: cover;
  padding: 100px 55px;
  .left {
    display: flex;
    flex-direction: column;
    gap: 16px;
    .topLeft {
      display: flex;
      flex-direction: column;
      gap: 8px;
      .LeftMap {
        img {
          width: 23px;
          // object-fit: cover;
        }
      }
      .spanYellow {
        color: #f7ce46;
        // font-size: 20px;
        font-size: calc(14px + (20 - 14) * ((100vw - 280px) / (1600 - 280)));
        font-style: normal;
        font-weight: 600;
        line-height: 32px; /* 160% */
      }
      .spanH1 {
        color: var(--primary-60, #142f58);
        font-size: calc(14px + (32 - 14) * ((100vw - 280px) / (1600 - 280)));
        // font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: 48px; /* 150% */
        width: 100%;
      }
    }
    .bottomLeft {
      display: flex;
      flex-wrap: wrap;
      gap: 8px;
      width: 500px;
      .countries {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 8px 24px;
        border-radius: 100px;
        border: 1px solid var(--neutral-50, #ced4da);
        background: var(--neutral-10, #fff);
        color: #333;
        text-align: center;
        // font-size: calc(6.5px + (12 - 6.5) * ((100vw - 280px) / (1600 - 280)));
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 18px */
        letter-spacing: 0.06px;
      }
    }
  }
  .right {
    display: flex;
    position: relative;
    .imageGroup {
      position: absolute;
      width: 3%;
      // height: 16px;
      right: 40%;
      top: 20%;
    }
    .ImgMap {
      width: 90%;
      object-fit: cover;
      //  height: 100%;
    }
  }
}

.containerResponsive {
  display: none;
}

@media (max-width: 1210px) {
  .Container {
    .right {
      width: 100%;
    }

    .left {
      .bottomLeft {
        width: 100%;
      }
    }
  }
}

@media (max-width: 1000px) {
  .Container {
    display: none;
  }

  .containerResponsive {
    width: 100vw;
    height: 100%;
    // height: 509px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    background: var(--neutral-20, #f8f9fa);
    padding: 32px 24px;
    .top {
      display: flex;
      flex-direction: column;
      gap: 4px;
      .spanH1 {
        color: var(--primary-60, #142f58);
        font-size: calc(14px + (32 - 14) * ((100vw - 280px) / (1600 - 280)));
        // font-size: 32px;
        font-style: normal;
        font-weight: 700;
        // line-height: 48px; /* 150% */
        width: 80%;
      }
      .companyLocationBox {
        display: flex;
        align-items: center;
        gap: 4px;
        .LeftMap {
          img {
            width: 23px;
            // object-fit: cover;
          }
        }
        .spanYellow {
          color: #f7ce46;
          // font-size: 20px;
          font-size: calc(14px + (20 - 14) * ((100vw - 280px) / (1600 - 280)));
          font-style: normal;
          font-weight: 600;
          line-height: 32px; /* 160% */
        }
      }
    }

    .imageMap {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      position: relative;
      // object-fit: cover;
      .imageGroup{
        position: absolute;
        width: 3%;
        right: 33.4%;
        top: 20%;

      }
      img {
        object-fit: cover;
      }
    }

    .boxBottom {
      display: flex;
      justify-content: center;
    }
    .bottom {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 8px;
      width: 100%;
      .countries {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 8px 16px;
        border-radius: 100px;
        border: 1px solid var(--neutral-50, #ced4da);
        background: var(--neutral-10, #fff);
        color: #333;
        text-align: center;
        // font-size: calc(6.5px + (12 - 6.5) * ((100vw - 280px) / (1600 - 280)));
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 18px */
        letter-spacing: 0.06px;
      }
    }
  }
}
