$animationSpeed: 400ms;
$landingBanner1: url("../../assets/png/BannerLayout/landing-banner-1.jpg");
$landingBanner2: url("../../assets/png/BannerLayout/landing-banner-2.jpg");
$landingBanner3: url("../../assets/png/BannerLayout/landing-banner-3.jpg");

.container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  // min-height: 740px;
  // max-height: 900px;
  // position: relative;
  position: relative;
  &.Background_1 {
    background-image: linear-gradient(
        180deg,
        #000 0%,
        rgba(0, 0, 0, 0) 70%,
        #000
      ),
      $landingBanner1;
    background-repeat: no-repeat;
    background-size: cover;
    // background-attachment: fixed;
    background-position: center;
    // background: #000;
    // opacity: 50%;
    // opacity: 10%;
    // width: 100%;
    // height: 100%;
    // opacity: 30%;
    .Indicator {
      & > div {
        border: 1px solid #000;

        &.Active {
          background-color: #000;
        }
      }
    }
  }
  &.Background_2 {
    background-image: linear-gradient(
        180deg,
        #000 0%,
        rgba(0, 0, 0, 0) 70%,
        #000
      ),
      $landingBanner2;
    background-repeat: no-repeat;
    background-size: cover;
    // background-attachment: fixed;
    background-position: center;
    // width: 100%;
    // height: 100%;
    .Indicator {
      & > div {
        border: 1px solid #000;

        &.Active {
          background-color: #000;
        }
      }
    }
  }
  &.Background_3 {
    background-image: linear-gradient(
        180deg,
        #000 0%,
        rgba(0, 0, 0, 0) 79.71%,
        #000
      ),
      $landingBanner3;
    background-repeat: no-repeat;
    background-size: cover;
    // background-attachment: fixed;
    background-position: center;
    // opacity: 10%;
    // width: 100%;
    // height: 100%;

    .Indicator {
      & > div {
        border: 1px solid #000;

        &.Active {
          background-color: #000;
        }
      }
    }
  }
}

.boxHome {
  display: flex;
  flex-direction: column;
  // margin-top: 150px;
  padding: 120px 56px;
  height: 100%;
  // gap: 80px;
  // gap: 100px;
  // position: relative;
  .boxTitle {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    // position: relative;
    .left {
      display: flex;
      flex-direction: column;
      // gap: 10px;
      animation: fadeIn 2s;
      .textSpecialist {
        color: #f7ce46;

        /* Subtitle/1 */
        // font-family: Poppins;
        font-size: calc(10px + (20 - 10) * ((100vw - 280px) / (1600 - 280)));
        font-style: normal;
        font-weight: 600;
        // line-height: normal;
      }
      .H4 {
        color: var(--neutral-30, #e9ecef);
        // font-family: Poppins;
        width: 60%;
        font-size: calc(14px + (55 - 14) * ((100vw - 280px) / (1600 - 280)));
        font-style: normal;
        font-weight: 700;
        // line-height: 80px; /* 111.111% */
      }
      .H1 {
        color: #11bde6;

        /* Headings/H2 */
        // font-family: Poppins;
        font-size: calc(20px + (32 - 20) * ((100vw - 280px) / (1600 - 280)));
        font-style: normal;
        font-weight: 700;
        // line-height: 46px; /* 143.75% */
      }
      .p {
        color: var(--neutral-10, #fff);
        font-size: calc(8px + (20 - 8) * ((100vw - 280px) / (1600 - 280)));
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        width: 70%;
      }
    }
  }
}

.Indicator {
  display: flex;
  flex-direction: column;
  gap: 10px;
  user-select: none;
  position: absolute;
  // bottom: 0;
  // padding-top: 160px;
  // position: absolute;
  // top: 40%;
  right: 30px;
  padding-inline: 20px;

  & > div {
    cursor: pointer;
    height: 12px;
    aspect-ratio: 1;
    border: 1px solid #d9d9d9;
    border-radius: 50%;
    transition: $animationSpeed;

    &.Active {
      background-color: #d9d9d9;
    }
  }
}

.featureBox {
  position: absolute;
  bottom: 30px;
  // position: relative;
  // top: 0px;
  left: 0px;
  right: 0px;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media (max-width: 1280px) {
  .boxHome {
    .boxTitle {
      .left {
        .H4 {
          width: 100%;
        }
      }
    }
  }
}

@media (max-width: 1000px) {
  .Indicator {
    bottom: 500px;
  }
  .container {
    // max-height: 700px;
  }
  // padding: 0px 24px;
  .boxHome {
    padding: 106px 24px;
  }
}

@media (max-width: 570px) {
  .Indicator {
    bottom: 450px;
  }
  .container {
    // max-height: none;
    // height: 100vh;
    &.Scrolled {
      .boxTitle {
        opacity: 0;
      }
      .featureBox {
        opacity: 1;
        pointer-events: visible;
      }
    }
  }
  .featureBox {
    opacity: 1;
    pointer-events: none;
  }

  .featureBox {
    opacity: 0;
    position: absolute;
    top: 20%;
    left: 0px;
    right: 0px;
    padding: 24px 10px;
  }
  // .Features {
  //   opacity: 1;
  //   pointer-events: none;
  // }
  // // &.Scrolled {
  // //   .Intermezzo {
  // //     opacity: 0;
  // //   }
  // //   .Features {
  // //     opacity: 1;
  // //     pointer-events: visible;
  // //   }
  // // }

  // .Intermezzo {
  //   transform: translateY(70px);
  //   opacity: 1;
  //   pointer-events: none;
  //   & > .Switcher {
  //     // bottom: 0px;
  //     // padding-inline: 30px;
  //     transform: translateX(-150vw);
  //     .TitleBox {
  //       bottom: 300px;
  //       .textOne {
  //         width: 100%;
  //       }
  //       .textTwo {
  //         font-size: 16px;
  //         // line-height: 50px; /* 143.75% */
  //       }
  //       .textThree {
  //         width: 100%;
  //         font-size: 14px;
  //         // line-height: normal;
  //       }

  //       .textThreeSlide2 {
  //         color: var(--neutral-10, #fff);
  //         // font-family: Poppins;
  //         font-size: 14px;
  //         font-style: normal;
  //         // font-weight: 700;
  //         // width: 60%;
  //       }

  //       .textThreeSlide3 {
  //         color: var(--neutral-10, #fff);
  //         // font-family: Poppins;
  //         font-size: 14px;
  //         font-style: normal;
  //         // font-weight: 600;
  //         width: 100%;
  //       }
  //     }
  //     // .Title {
  //     //   & > .h1 {
  //     //     line-height: 35px;
  //     //     font-size: calc(60em / 14);
  //     //     width: 100%;
  //     //   }
  //     //   & > .h2 {
  //     //     font-size: calc(35em / 14);
  //     //     line-height: 25px;
  //     //   }
  //     //   & > .h3 {
  //     //     font-size: calc(30em / 14);
  //     //     line-height: 18.2px;
  //     //   }
  //     // }

  //     // .Description {
  //     //   & > p {
  //     //     font-size: calc(25em / 14);
  //     //     line-height: 18.2px;
  //     //     width: 90%;
  //     //   }
  //     // }

  //     &.Light {
  //       padding-top: 50px;
  //     }
  //   }
  // }

  // .Features {
  //   opacity: 0;
  //   // gap: 8px;
  //   // display: grid;
  //   // grid-template-columns: 1fr 1fr;
  //   // // width: auto;
  //   right: 42%;
  //   // transform: translateX(50%) translateY(50%);
  //   bottom: 40%;
  //   padding: 24px;
  // }
}
