.Container {
  width: 100%;
  padding-block: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .Extender {
    // height: 90vh;
    // min-height: 740px;
    // width: 100%;
    // max-height: 800px;
  }

  & > .Body {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 550px;

    .Illustration {
      display: flex;
      position: relative;
      align-items: center;
      transform: translateX(-80px);
      z-index: 1;
      img {
        border-radius: 6px;
        width: 422px;
        height: 550px;
        // height: 100%;
        // width: 100%;
        object-fit: cover;
        background-position: center;
      }
    }

    .Control {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      z-index: 2;
      & > .Title {
        & > .h1,
        h3 {
          display: block;
        }
        & > .h1 {
          font-size: calc(40em / 14);
          line-height: 52px;
          font-weight: 600;
          text-transform: uppercase;
          color: #011e4b;
        }
        & > .h3 {
          font-size: calc(16em / 14);
          line-height: 21.6px;
          margin-top: 5px;
          color: #333333;
        }
      }
      .Content {
        display: flex;
        flex-direction: column;
        gap: 30px;
        padding-bottom: 10px;
        .Card {
          width: 566px;
          height: 240px;
          border-radius: 5px;
          background-color: #fff;
          box-shadow: 7px 3px 4px 0px #00000012;
          z-index: 2;
          & > .Body {
            padding-block: 30px;
            padding-inline: 25px;
            position: absolute;
            opacity: 0;
            user-select: none;
            width: 566px;
            transition: 400ms;
            &.Active {
              opacity: 1;
            }
            span {
              color: #333333;
              display: block;
              &:first-child {
                font-size: calc(26em / 14);
                font-weight: 500;
                text-transform: uppercase;
                line-height: 33.8px;
              }
              &:last-child {
                margin-top: 25px;
                line-height: 25.2px;
              }
            }
          }
        }
        .Switch {
          height: 50px;
          display: flex;
          gap: 5px;

          .Button {
            height: 100%;
            aspect-ratio: 1;
            background-color: #fff;
            border-radius: 2px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            user-select: none;
            &.Disabled {
              cursor: default;
            }
            .Icon {
              font-size: 24px;
            }
          }
        }
      }
    }
  }

  .Background {
    position: absolute;
    z-index: -2;
    width: 100vw;
    height: 710px;
    display: flex;
    div {
      &:first-child {
        flex: 2;
        background-color: #f7f7f9;
        height: 100%;
      }
      &:last-child {
        flex: 1;
        background-color: #ffffff;
        height: 100%;
      }
    }
  }

  @media (max-width: 1200px) {
    .Extender {
      // height: 100vh;
    }

    & > .Body {
      height: 100%;
      position: relative;
      top: 40px;
      // left: 50px;

      // right: 20px;
      .Illustration {
        transform: translateX(-80px);
        z-index: 1;
        position: relative;
        left: 40px;
        img {
          border-radius: 6px;
          // height: 100%;
          width: 400px;
          height: 400px;
          object-fit: cover;
          background-position: center;
        }
      }

      .Control {
        & > .Title {
          & > .h1 {
            line-height: 45px;
          }
          & > .h3 {
            line-height: 18.6px;
            margin-top: 10px;
            color: #333333;
          }
        }
        .Content {
          gap: 25px;
          padding-bottom: 10px;
          padding-top: 10px;
          .Card {
            width: 320px;
            // height: 500px;
            & > .Body {
              padding-block: 20px;
              padding-inline: 20px;
              width: 320px;
              span {
                &:first-child {
                  line-height: 27.8px;
                }
                &:last-child {
                  margin-top: 25px;
                  line-height: 20.2px;
                }
              }
            }
          }
          .Switch {
            height: 35px;
            gap: 5px;
          }
        }
      }
    }
    .Background {
      height: 700px;
    }
  }
}

.containerResponsive {
  display: none;
}

@media (max-width: 768px) {
  .Container {
    display: none;
  }
  .containerResponsive {
    display: flex;
    flex-direction: column;
    width: 100vw;
    padding: 32px;
    background: var(--neutral-20, #f8f9fa);
    .Extender {
      // height: 700px;
      // min-height: 700px;
      width: 100%;
      // max-height: 800px;
    }

    .body {
      display: flex;
      flex-direction: column;
      gap: 16px;
      .titleValues {
        display: flex;
        flex-direction: column;
        gap: 4px;
        .span1 {
          color: var(--primary-60, #142f58);
          font-size: 32px;

          font-style: normal;
          font-weight: 700;
          line-height: 46px; /* 143.75% */
        }
        .span2 {
          color: var(--neutral-100, #000);
          font-size: 18px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }
      }

      .Content {
        display: flex;
        flex-direction: column;
        gap: 16px;
        position: relative;
        // top:110px;
        // padding-bottom: 10px;
        .Card {
          // width: 566px;
          height: 180px;
          border-radius: 8px;
          background: var(--neutral-20, #f8f9fa);
          /* Vertical Card Shadow */
          box-shadow: 0px 0px 8px 4px rgba(0, 0, 0, 0.1);
          z-index: 2;
          & > .Body {
            padding: 20px 32px;
            display: flex;
            flex-direction: column;
            gap: 16px;
            // padding-block: 30px;
            // padding-inline: 25px;
            position: absolute;
            opacity: 0;
            user-select: none;
            transition: 400ms;
            &.Active {
              opacity: 1;
            }
            span {
              display: block;
              &:first-child {
                color: #333333;
                font-size: 26px;
                font-style: normal;
                font-weight: 500;
                line-height: 36px; /* 138.462% */
              }
              &:last-child {
                color: var(--neutral-70, #6c757d);
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px; /* 142.857% */
              }
            }
          }
        }
        .Switch {
          height: 50px;
          display: flex;
          gap: 5px;

          .Button {
            height: 100%;
            aspect-ratio: 1;
            background-color: #fff;
            border-radius: 2px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            user-select: none;
            &.Disabled {
              cursor: default;
            }
            .Icon {
              font-size: 24px;
              // width: 24px;
            }
          }
        }
      }

      .imagesValue {
        width: 100%;
        // height: 400px;
        // padding: 0px 32px;
        border-radius: 8px;
        // height: 50%;
        // height: 0%;
        // padding: 0px 100px;
        // background-size: cover;
        // background-position: center;
        // background-repeat: no-repeat;
        border-radius: 8px;
        img {
          border-radius: 8px;
          height: 550px;

          // height: 400px;
          // // height: 450px;
          width: 100%;
          object-fit: cover;
          background-position: center;
        }
      }
    }
  }
}

@media (max-width: 570px) {
  .containerResponsive {
    padding-left: 24px;
    padding-right: 24px;
    .Extender {
      // height: 100%;
      // height: 100%;
      width: 100%;
      // height: 100vh;
      // min-height: 100vh;
    }

    .body {
      .titleValues {
        .span2 {
          color: var(--neutral-100, #000);
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }
      }
      .Content {
        .Card {
          height: 200px;
          & > .Body {
            gap: 8px;
            padding: 20px 20px;
            span {
              // display: block;
              &:first-child {
                // color: #333333;
                font-size: 20px;
              }
              &:last-child {
                // color: var(--neutral-70, #6C757D);
                font-size: 12px;
              }
            }
          }
        }
      }

      .imagesValue {
        padding: 0px;
        img {
          height: 400px;
        }
      }
    }
  }
}
