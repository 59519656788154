.Container {
  background-color: transparent;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 56px;
  padding-right: 70px;
  position: absolute;
  top: 0;
  width: 100%;
  transition: 400ms;
  z-index: 4;

  &.Blur {
    background-color: rgba(255, 255, 255, 0.6);
    backdrop-filter: blur(10px);
    position: fixed;
    .Nav {
      padding-block: 30px;
    }
    .Logo {
      img {
        width: 120px;
      }
    }
  }

  .Logo {
    pointer-events: none;
    transition: 400ms;
    img {
      width: 169px;
    }
  }

  .Nav {
    display: flex;
    gap: 30px;
    padding-block: 60px;
    transition: 400ms;
    .a {
      position: relative;
      cursor: pointer;
      font-weight: 500px;
      text-align: center;
      text-decoration: none;
      font-size: calc(9px + (14 - 9) * ((100vw - 280px) / (1600 - 280)));
      &.active {
        border-bottom: 1px solid black;
        padding-bottom: 5px;
      }
    }

    .a:not(.active)::before {
      content: "";
      position: absolute;
      height: 1px;
      width: 100%;
      background: #000;
      left: 0;
      bottom: -1px;
      transform: scale(0, 1);
    }

    .a:not(.active):hover::before {
      transform: scale(1, 1);
    }

    &.Light {
      .a {
        color: #011e4b;
        &.active {
          border-bottom: 1px solid #000;
          padding-bottom: 5px;
        }
      }

      .a::before {
        content: "";
        position: absolute;
        height: 1px;
        width: 100%;
        background: #000;
        left: 0;
        bottom: -1px;
        transform: scale(0, 1);
      }

      .a:hover::before {
        transform: scale(1, 1);
      }
    }

    &.Dark {
      .a {
        text-align: center;
        color: #fff;
        &.active {
          border-bottom: 1px solid #fff;
          padding-bottom: 5px;
        }
      }

      .a::before {
        content: "";
        position: absolute;
        height: 1px;
        width: 100%;
        background: #fff;
        left: 0;
        bottom: -1px;
        transform: scale(0, 1);
      }

      .a:hover::before {
        transform: scale(1, 1);
      }
    }
  }

  .Menu {
    // position: absolute;
    // right: 15px;
    opacity: 0;
    pointer-events: none;
  }

  @media (max-width: 1000px) {
    padding-left: 26px;
    padding-right: 26px;

    .Logo {
      img {
        width: 100px;
      }
    }

    .Nav {
      // width:55%;
      padding-block: 45px;
      // gap: 24px;
    }
  }

  @media (max-width: 570px) {
    padding-left: 16px;
    padding-right: 16px;

    &.Blur {
      .Nav {
        padding-block: 18px;
      }
      .Logo {
        img {
          width: 60px;
        }
      }
    }

    .Logo {
      img {
        width: 80px;
      }
    }

    .Nav {
      // padding-block: 20px;
      gap: 15px;
      opacity: 0;
      pointer-events: none;
    }

    .Menu {
      opacity: 1;
      pointer-events: visible;
    }

    .Logo {
      display: none;
    }
  }
}

.Dropdown {
  display: flex;
  width: 100vw;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 4;
  height: 100%;
  .boxDropdown {
    color: #011e4b;
    // width: 70%;
    width: 500px;
    background: #fff;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 42px;
    padding: 42px 32px;
    .logoPermata {
      display: flex;
      justify-content: space-between;
      .imagesLogo {
        width: 50%;
      }
      .imagesClose {
        width: 24px;
        height: 24px;
      }
    }
    .routesResponsive {
      display: flex;
      flex-direction: column;
      gap: 30px;
      .a {
        text-decoration: none;
        color: var(--neutral-100, #000);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      .a::before {
        content: "";
        position: absolute;
        height: 1px;
        width: 100%;
        background: #000;
        left: 0;
        bottom: -5px;
        transform: scale(0, 1);
      }

      .a:hover::before {
        transform: scale(1, 1);
      }
    }
  }
  .closeDropdown {
    width: 100%;
  }
}
