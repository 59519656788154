$BackAddedValue: url("../../assets/png/AddedValueLayout/BackAddedValue.png");

.Container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  // background: #F8F9FA;
  background-image: $BackAddedValue;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  & > .Kotak {
    width: 100%;
    & > .LeftBody {
      width: 50%;
      // padding: 20px;
      // background: #011e4b;
      float: left;
      & > .ContentBody {
        display: flex;
        width: 100%;
        // margin-left: 25%;
        // margin-top: 159px;
        left: 100px;
        top: 159px;
        z-index: 1;
        // // top: 0;
        position: relative;
        // transform: translateY(-900px);
        border-radius: 8px;
        background: var(--neutral-10, #fff);
        box-shadow: 0px 0px 8px 4px rgba(0, 0, 0, 0.1);
        padding: 32px;
        & > .tes {
          width: 100%;
          & > .LeftTitle1 {
            width: 100%;
            font-family: "Poppins";
            font-style: normal;
            font-weight: 500;
            font-size: calc(
              14px + (18 - 14) * ((100vw - 280px) / (1600 - 280))
            );
            line-height: 27px;
            color: #12bce5;
          }
          & > .LeftTitle2 {
            width: 100%;
            // height: 52px;
            top: 80px;
            font-family: "Poppins";
            font-style: normal;
            font-weight: 600;
            font-size: calc(
              14px + (40 - 14) * ((100vw - 280px) / (1600 - 280))
            );
            line-height: 130%;
            color: #011e4b;
          }
          & > .LeftDesc {
            // position: relative;
            width: 100%;
            // left: 40px;
            margin-top: 20px;
            margin-bottom: 30px;
            font-family: "Poppins";
            font-style: normal;
            font-weight: 400;
            font-size: calc(
              14px + (16 - 14) * ((100vw - 280px) / (1600 - 280))
            );
            line-height: 180%;
            color: #333333;
          }
        }
      }
    }

    & > .RightBody {
      width: 50%;
      float: left;
      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
  }
}

.containerResponsive {
  display: none;
}

@media (max-width: 1024px) {
  .Container {
    // padding-bottom: 0px;
    .Kotak {
      .LeftBody {
        .ContentBody {
          top: 30px;
        }
      }
    }
  }
}

@media (max-width: 750px) {
  .Container {
    display: none;
  }

  .containerResponsive {
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100%;
    background: #ffffff;
    // gap: 32px;
    .imagesBox {
      // height: 50%;
      width: 100%;
      img {
        height: 350px;
        // height: 0%;
        width: 100%;
        object-fit: cover;
      }
    }

    .titleValue {
      display: flex;
      flex-direction: column;
      gap: 16px;
      padding: 32px 24px;
      .topValue {
        display: flex;
        flex-direction: column;
        gap: 4px;
        .trustedText {
          color: #12bce5;
          // font-family: Poppins;
          font-size: calc(14px + (18 - 14) * ((100vw - 280px) / (1600 - 280)));
          font-style: normal;
          font-weight: 500;
          line-height: 26px; /* 144.444% */
        }
        .addedText {
          color: var(--primary-60, #142f58);
          // font-family: Poppins;
          font-size: calc(14px + (40 - 14) * ((100vw - 280px) / (1600 - 280)));
          font-style: normal;
          font-weight: 600;
          // line-height: 52px; /* 130% */
        }
      }

      .ket {
        color: var(--neutral-70, #6c757d);
        // font-family: Poppins;
        font-size: calc(14px + (16 - 14) * ((100vw - 280px) / (1600 - 280)));
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 150% */
      }
    }
  }
}
