$intermezzoBackground: url("../../assets/png/VisionsLayout/celebration.png");

.Container {
  display: flex;
  flex-direction: column;
  // align-items: center;
  gap: 32px;
  padding: 100px 55px 180px 55px;
  // height: 100vh;
  height: 100%;
  .visionMission {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 8px;
    .textP {
      color: #333;
      // font-family: Poppins;
      font-size: calc(10px + (20 - 10) * ((100vw - 280px) / (1600 - 280)));
      font-style: normal;
      font-weight: 600;
      line-height: 135%; /* 27px */
      text-align: center;
    }
    .textTop {
      text-align: center;
      color: var(--primary-60, #142f58);
      // font-family: Poppins;
      font-size: calc(20px + (40 - 20) * ((100vw - 280px) / (1600 - 280)));
      font-style: normal;
      font-weight: 700;
      // line-height: 56px; /* 140% */
    }
  }
  // width: 100vw;
  // height: 734px;
  // display: flex;
  // align-items: center;
  // flex-direction: column;

  // .Background {
  //   z-index: -2;
  //   width: 100vw;
  //   height: 734px;
  //   display: flex;
  //   position: absolute;
  //   div {
  //     &:first-child {
  //       flex: 2;
  //       background-color: #ffffff;
  //       height: 100%;
  //     }
  //     &:last-child {
  //       flex: 1;
  //       background-color: #f7f7f9;
  //       height: 100%;
  //     }
  //   }
  // }

  // .Control {
  //   display: flex;
  //   flex-direction: column;
  //   padding-top: 50px;
  //   padding-bottom: 80px;
  //   height: 100%;
  //   justify-content: space-between;
  //   align-items: center;
  //   & > .Title {
  //     span {
  //       display: block;
  //       width: 100%;
  //       text-align: center;
  //       margin-top: 10px;
  //     }
  //     .h1 {
  //       color: #011e4b;
  //       font-size: calc(32em / 14);
  //       font-weight: 600;
  //       line-height: 41.6px;
  //     }
  //     span:not(.h1) {
  //       color: #333333;
  //       font-size: calc(16em / 14);
  //       font-weight: 500;
  //       line-height: 21.6px;
  //     }
  //   }

  //   & > .Content {
  //     display: flex;
  //     flex-direction: column;
  //     justify-content: flex-end;
  //     align-content: flex-start;
  //     width: 100vw;
  //     padding-left: 30px;
  //     padding-right: 30px;
  //     .Intermezzo {
  //       position: absolute;
  //       display: flex;
  //       flex-direction: column;
  //       align-items: flex-start;
  //       justify-content: flex-end;
  //       width: 470px;
  //       height: 440px;
  //       border-radius: 5px;
  //       background: $intermezzoBackground;
  //       background-repeat: no-repeat;
  //       background-position: center;
  //       background-size: cover;
  //       // background-attachment: fixed;
  //       background-color: red;
  //       padding: 20px;
  //       // padding-left: 100px;
  //       left: 58px;
  //       transform: translateY(-40px);
  //       & > .Title {
  //         color: #011e4b;
  //         font-weight: 700;
  //         font-size: calc(2em);
  //         line-height: 33.6px;
  //         span {
  //           color: #f7ce46;
  //           font-weight: 600;
  //         }
  //       }
  //       & > span {
  //         display: block;
  //         &:not(.Title) {
  //           color: #595959;
  //           line-height: 17.4px;
  //           font-size: calc(12em / 14);
  //           margin-top: 5px;
  //         }
  //       }
  //     }

  //     .Pillars {
  //       position: relative;
  //       // left: 20p
  //       left: 0px;
  //       // right: 25px;
  //       top: 60px;
  //       display: flex;
  //       gap: 10px;
  //       // width: 100vw;
  //       overflow: overlay;
  //       padding-block: 40px;

  //       // padding-left: 100px;
  //       transform: translateY(20px);
  //       &::-webkit-scrollbar {
  //         background: transparent; // manage scrollbar background color here
  //       }
  //       &::-webkit-scrollbar-thumb {
  //         background: transparent !important; // manage scrollbar thumb background color here
  //       }

  //       .Pusher {
  //         // padding-left: 20px;
  //         // background-color: #f7ce46;
  //         &:first-child {
  //           // padding-left: 20px;
  //           // width: 340px;
  //           min-width: 320px;
  //         }
  //         &:last-child {
  //           @media (max-width: 1407px) {
  //             width: 100px;
  //             min-width: 100px;
  //           }
  //           width: 0px;
  //           min-width: 0;
  //         }
  //       }
  //     }
  //   }
  // }

  // @media (max-width: 1200px) {
  //   height: 800px;
  //   // padding-left: 100px;
  //   .Control {
  //     padding-top: 110px;

  //     // padding-left: 100px;
  //     // padding-bottom: 100px;
  //   }
  // }

  // @media (max-width: 768px) {
  //   height: auto !important;

  //   .Background {
  //     height: 480px;
  //   }

  //   .Control {
  //     padding-top: 25px;
  //     gap: 16px;
  //     & > .Title {
  //       span {
  //         margin-top: 10px;
  //       }
  //       .h1 {
  //         line-height: 24.6px;
  //       }
  //       span:not(.h1) {
  //         line-height: 10.6px;
  //       }
  //     }

  //     & > .Content {
  //       padding-inline: 24px;
  //       gap: 16px;
  //       .Intermezzo {
  //         width: 100% !important;
  //         height: 400px;
  //         border-radius: 5px;
  //         padding: 20px;
  //         left: 10px;
  //         transform: none !important;
  //         position: unset;
  //         & > .Title {
  //           line-height: 17.6px;
  //         }
  //         & > span {
  //           display: block;
  //           &:not(.Title) {
  //             line-height: 10.4px;
  //             margin-top: 5px;
  //           }
  //         }
  //       }

  //       .Pillars {
  //         gap: 5px;
  //         padding-block: 0 !important;
  //         transform: none !important;
  //         position: unset !important;
  //         overflow: scroll !important;
  //         width: auto !important;
  //         display: -webkit-box !important;

  //         .Pusher {
  //           display: none !important;
  //         }
  //       }
  //     }
  //   }
  // }
}

.cardVission {
  display: flex;
  // align-items: ;
  top: 50px;
  right: 4%;
  width: 75%;
  height: 100%;
  gap: 4px;

  position: absolute;
  .box {
    cursor: pointer;
    border-radius: 5px;
    background-color: #ffffff;
    box-shadow: 1px 3px 4px 3px #00000012;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    z-index: 1;
    display: flex;
    flex-direction: column;
    width: 50%;
    // height: 100%;
    transition: 400ms;
    &:hover {
      transform: scale(115%);
      z-index: 3;
    }
    .blue {
      height: 6px;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
      background-color: #011e4b;
      width: 100%;
    }
    .top {
      display: flex;
      flex-direction: column;
      gap: 32px;
      padding: 16px;
      height: 100%;
      .number {
        color: #333;
        // font-family: Poppins;
        font-size: calc(16px + (26 - 16) * ((100vw - 280px) / (1600 - 280)));
        font-style: normal;
        font-weight: 500;
        line-height: 130%; /* 33.8px */
      }
      .textDesc {
        color: #333;
        // font-family: Poppins;
        font-size: calc(12px + (14 - 12) * ((100vw - 280px) / (1600 - 280)));
        font-style: normal;
        font-weight: 400;
        line-height: 30px; /* 214.286% */
      }
    }
  }
}

.contentVisionBox {
  display: flex;
  align-items: center;
  position: relative;
  .boxImages {
    display: flex;
    flex-direction: column;
    position: relative;
    .textImagesResponsove {
      display: none;
    }
    .textImages {
      position: absolute;
      padding: 16px;
      bottom: 0;
      display: flex;
      flex-direction: column;
      .litleDesc {
        color: #595959;
        // font-family: Poppins;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        width: 80%;
        // line-height: 145%; /* 17.4px */
      }
      .blueText {
        color: #011e4b;
        // font-family: Poppins;
        font-size: calc(18px + (24 - 18) * ((100vw - 280px) / (1600 - 280)));
        font-style: normal;
        font-weight: 600;
        line-height: 120%; /* 33.6px */
        .yellowText {
          color: #f7ce46;
          // font-family: Poppins;
          font-size: calc(18px + (24 - 18) * ((100vw - 280px) / (1600 - 280)));
          font-style: normal;
          font-weight: 700;
          // line-height: 120%;
        }
      }
    }
    .images {
      width: 90%;
      border-radius: 5px;

      img {
        border-radius: 5px;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}

@media (max-width: 1280px) {
  .Container {
    padding-top: 150px;
    padding-bottom: 150px;
  }

  .cardVission {
    width: 90%;
    top: 70px;
    .box {
      width: 100%;
    }
  }
  .contentVisionBox {
    .boxImages {
      .textImages {
        top: 0;
        // right: 0;
        .litleDesc {
          width: 100%;
          // width: 80%;
        }

        .blueText {
          // font-size: 16px;
          .yellowText {
            // font-size: 16px;
          }
        }
      }
      .images {
        // width: 50%;
      }
    }
  }
}

@media (max-width: 900px) {
  .Container {
    padding: 150px 24px 50px 24px;
  }

  .contentVisionBox {
    flex-direction: column;
    gap: 16px;
    .boxImages {
      width: 100%;
      .textImages {
        display: none;
      }
      .textImagesResponsove {
        position: absolute;
        padding: 16px;
        bottom: 0;
        display: flex;
        flex-direction: column;
        .litleDesc {
          color: #595959;
          // font-family: Poppins;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          // line-height: 145%; /* 17.4px */
        }
        .blueText {
          color: #011e4b;
          // font-family: Poppins;
          font-size: calc(18px + (28 - 18) * ((100vw - 280px) / (1600 - 280)));
          font-style: normal;
          font-weight: 600;
          line-height: 120%; /* 33.6px */
          .yellowText {
            color: #f7ce46;
            // font-family: Poppins;
            font-size: calc(
              18px + (28 - 18) * ((100vw - 280px) / (1600 - 280))
            );
            font-style: normal;
            font-weight: 700;
            line-height: 120%;
          }
        }
      }

      .images {
        width: 100%;
        height: 400px;
      }
    }
  }

  .cardVission {
    display: grid;
    grid-auto-flow: column;
    scroll-behavior: auto;
    gap: 16px;
    overflow-y: auto;
    overscroll-behavior-x: contain;
    scroll-snap-type: x mandatory;
    scrollbar-width: none;
    // width: 100%;
    position: relative;
    width: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0px;
    .box {
      width: 228px;
      height: 400px;
    }
  }
}

@media (max-width: 768px) {
  .Container {
    padding: 32px 24px;
  }
}
