.wrapperFooter {
  padding: 40px 70px;
  background-color: #04132a;
  display: flex;
  flex-direction: column;
  row-gap: 40px;
  align-items: center;
  .mainFooter {
    width: 100%;
    display: flex;
    justify-content: space-between;
    color: #fff;
    .aboutCompany {
      display: flex;
      flex-direction: column;
      row-gap: 8px;
      .wrapLogo {
        padding: 15px 11px;
        width: max-content;
        > img {
          width: 164px;
          height: 51px;
        }
      }

      span {
        color: #fff;
        font-family: Poppins;
        font-size: 18px;
        font-weight: 600;
        line-height: normal;
      }
      .address {
        display: flex;
        flex-direction: column;
        row-gap: 8px;
        color: #fff;
        h3 {
          font-family: Poppins;
          font-size: 18px;
          font-weight: 600;
        }
        p {
          font-family: Poppins;
          font-size: 14px;
          font-weight: 400;
          letter-spacing: 0.07px;
        }
        > div {
          display: flex;
          gap: 8px;
          align-items: center;
          > img {
            width: 20px;
            height: 20px;
            flex-shrink: 0;
          }
          .addressPoint {
            font-family: Poppins;
            font-size: 14px;
            font-weight: 400;
            letter-spacing: 0.07px;
            // width: 50%;
            width: 300px;
          }
        }
      }
    }
    .socialMedia {
      display: flex;
      flex-direction: column;
      row-gap: 24px;
      text-align: start;
      .follow {
        color: #fff;
        font-family: Poppins;
        font-size: 18px;
        font-weight: 600; //mixin
      }
      .wrapperIcon {
        display: flex;
        gap: 1rem;
        align-items: center;
        a img {
          cursor: pointer;
        }
      }
    }
    .contactUs {
      display: flex;
      flex-direction: column;
      row-gap: 1rem;
      color: #fff;
      .textHeader {
        color: #fff;
        font-family: Poppins;
        font-size: 18px;
        font-weight: 600; // mixin
      }
      .wrapperIcon {
        display: flex;
        flex-direction: column;
        row-gap: 12px;
        > div {
          display: flex;
          gap: 8px;
          align-items: center;
          .icon {
            width: 24px;
            height: 24px;
            flex-shrink: 0;
          }
          .text {
            font-size: 14px;
          }
        }
      }
    }
  }
  .bottom {
    .copyright {
      color: #fff;
      font-family: Poppins;
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 0.07px;
    }
  }
}
@media (max-width: 768px) {
  .wrapperFooter {
    row-gap: 24px;
    padding: 2rem;
    .mainFooter {
      display: flex;
      flex-direction: column;
      row-gap: 1rem;

      .aboutCompany {
        .wrapLogo {
          > img {
            width: 144px;
            height: 41px;
          }
        }
        .address {
          > div {
            .addressPoint {
              line-height: 130%;
              width: 100%;
            }
          }
        }
      }
    }
  }
}
