.Container {
  min-width: 239.5px;
  width: 239.5px;
  height: 420px;
  border-radius: 5px;
  background-color: #ffffff;
  box-shadow: 1px 3px 4px 3px #00000012;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  z-index: 1;
  display: flex;
  flex-direction: column;
  transition: 400ms;

  &:hover {
    transform: scale(115%);
    z-index: 3;
  }

  .Body {
    width: 100%;
    flex: 1;
    padding: 20px;
    display: flex;
    flex-direction: column;

    .Serial {
      font-size: calc(14px + (26 - 14) * ((100vw - 280px) / (1600 - 280)));
      font-weight: 500;
      color: #333333;
      line-height: 33.8px;
    }

    .Content {
      margin-top: 30px;
      color: #333;
      // font-family: Poppins;
      font-size: calc(10px + (14 - 10) * ((100vw - 280px) / (1600 - 280)));
      font-style: normal;
      font-weight: 400;
      line-height: 30px; /* 214.286% */
    }
  }

  .Deco {
    height: 6px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    background-color: #011e4b;
    width: 100%;
  }

  @media (max-width: 1200px) {
    // min-width: 200px;
    // width: 200px;
    // height: 380px;

    .Body {
      padding: 20px;

      .Content {
        margin-top: 30px;
        // line-height: 20.4px;
      }
    }
  }

  @media (max-width: 1000px) {
    // min-width: 180px;
    // width: 180px;
    // height: 100%;

    .Body {
      padding: 20px;

      .Content {
        margin-top: 30px;
      }
    }
  }

  @media (max-width: 900px) {
    // min-width: 150px;
    // width: 190px;

    &:hover {
      transform: none;
      z-index: 3;
    }
    .Body {
      padding: 20px;

      .Content {
        // font-size: 10px;
        margin-top: 30px;
        // line-height: 18.4px;
      }
    }
  }

  @media (max-width: 768px) {
    // min-width: 120px;
    // width: 185px;
    // height: 400px;

    &:hover {
      transform: none;
      z-index: 3;
    }
    .Body {
      padding: 20px;

      .Content {
        // font-size: 12px;
        margin-top: 20px;
        // line-height: 18.4px;
      }
    }
  }

  // @media (max-width: 570px) {
  //   min-width: 100px;
  //   width: 185px;
  //   height: 300px;

  //   .Body {
  //     padding: 20px;

  //     .Serial {
  //       font-size: calc(26rem / 14);
  //       line-height: 18.8px;
  //     }

  //     .Content {
  //       margin-top: 20px;
  //       line-height: 18px;
  //     }
  //   }
  // }
}

//   box-shadow: 1px 3px 4px 3px #00000012;
