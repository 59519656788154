$Spesialisedbackground: url("../../assets/png/Spesialised/Spesialisedbackground.png");

.Container {
  width: 100%;
  // height: 559px;
  height: 100%;
  display: flex;
  padding: 100px 55px;
  // align-items: center;
  // flex-direction: column;
  // align-items: flex-start;
  // justify-content: space-between;
  gap: 24px;
  background-color: #142f58;
  // & > .Kotak {
  //   width: 85%;
  //   height: 200px;
  //   // background: #f7ce46;
  //   transform: translateY(0);
  //   display: inline-block;
  //   // align-items: center;
  //   // justify-content: center;
  //   margin: auto;
  //   & > .LeftBody {
  //     height: 200px;
  //     width: 48%;
  //     // background: #2b4d7b;
  //     float: left;
  //     // left: 215px;
  //     // top: 0;
  //     // position: absolute;
  //     // transform: translateY(-205px);
  //     & > .LeftTitle1 {
  //       position: absolute;
  //       width: 300px;
  //       height: 27px;
  //       // left: 180px;
  //       // top: 180px;
  //       font-family: "Poppins";
  //       font-style: normal;
  //       font-weight: 500;
  //       font-size: 1.5vw;
  //       line-height: 27px;
  //       // transform: translateY(-40px);
  //       color: #f7ce46;
  //     }
  //     & > .LeftTitle2 {
  //       position: absolute;
  //       width: 435px;
  //       height: 76px;
  //       // left: 180px;
  //       top: 35px;
  //       font-family: "Poppins";
  //       font-style: normal;
  //       font-weight: 600;
  //       font-size: 2vw;
  //       line-height: 120%;
  //       color: #ffffff;
  //     }
  //     & > .Description {
  //       position: absolute;
  //       width: 347px;
  //       height: 42px;
  //       // left: 180px;
  //       top: 120px;
  //       font-family: "Poppins";
  //       font-style: normal;
  //       font-weight: 400;
  //       font-size: 1vw;
  //       line-height: 130%;
  //       color: #ffffff;
  //     }
  //   }
  //   & > .RightBody {
  //     height: 320px;
  //     width: 48%;
  //     // background: red;
  //     float: right;
  //     // display: inline-block;
  //     // position: absolute;
  //     display: flex;
  //     flex-direction: column;
  //     ul {
  //       list-style: none;
  //       width: 100%;
  //       height: 100%;
  //       margin: 0;
  //       padding: 0;
  //     }
  //     ul li {
  //       width: 50%;
  //       height: 180px;
  //       display: inline-block;
  //       margin: 0;
  //       padding: 5px;
  //       // background: greenyellow;
  //       & > .ListRight {
  //         float: left;
  //         // background: #f7ce46;
  //         box-sizing: border-box;
  //         // position: absolute;
  //         width: 100%;
  //         height: 120px;
  //         // left: 635px;
  //         // top: 30px;
  //         border: 2px solid #2b4d7b;
  //         border-radius: 5px;

  //         & > .ImgListRight {
  //           margin-left: 20px;
  //           margin-top: -30px;
  //         }
  //         & > .TitleListRight {
  //           // position: absolute;
  //           width: 146px;
  //           height: 21px;
  //           margin-left: 23px;
  //           // top: 35px;
  //           font-family: "Poppins";
  //           font-style: normal;
  //           font-weight: 600;
  //           font-size: 16px;
  //           line-height: 130%;
  //           color: #ffffff;
  //         }
  //         & > .DesListRight {
  //           // position: absolute;
  //           // width: 260px;
  //           height: 18px;
  //           margin-left: 23px;
  //           // top: 65px;
  //           font-family: "Poppins";
  //           font-style: normal;
  //           font-weight: 400;
  //           font-size: 13px;
  //           line-height: 135%;
  //           color: #d7d5d5;
  //         }
  //       }
  //     }
  //     ul li:first-child {
  //       margin: 0;
  //       float: left;
  //     }
  //     ul li:last-child {
  //       margin: 0;
  //     }
  //   }
  // }

  .leftBox {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 48%;
    .outsourcingManagement {
      color: #fff;
      // text-align: center;
      // font-family: Poppins;
      font-size: calc(14px + (32 - 14) * ((100vw - 280px) / (1600 - 280)));
      // font-size: 32px;
      font-style: normal;
      font-weight: 600;
      // line-height: 48px; /* 150% */
      // width: 95%;
    }
    .customized {
      color: #fff;
      // text-align: center;
      // font-family: Poppins;
      // font-size: 16px;
      font-size: calc(14px + (16 - 14) * ((100vw - 280px) / (1600 - 280)));
      font-style: normal;
      font-weight: 400;
      // line-height: 24px; /* 150% */
    }
    .specialistIn {
      color: #f7ce46;
      // font-family: Poppins;
      // font-size: 20px;
      // text-align: center;
      font-size: calc(14px + (20 - 14) * ((100vw - 280px) / (1600 - 280)));
      font-style: normal;
      font-weight: 500;
      // line-height: 32px; /* 160% */
    }
  }

  .rightBox {
    // display: flex;
    display: flex;
    flex-direction: column;
    gap: 16px;
    // flex-wrap: wrap;
    // gap: 30px;
    width: 90%;
    .optionBox {
      display: flex;
      flex-direction: column;
      gap: 32px;
    }
    .carouselOther {
      // margin-top: 20%;
      display: flex;
      align-items: center;
      // justify-content: center;
      // position: relative;
      // left: 1%;
      // padding: 0px 0px 0px 80px;
      width: 100%;
      .otherBox {
        display: flex;
        // align-items: center;
        gap: 16px;
        // display: grid;
        // grid-auto-flow: column;
        // scroll-behavior: auto;
        // gap: 16px;
        // overflow-y: auto;
        // overscroll-behavior-x: contain;
        // scroll-snap-type: x mandatory;
        // scrollbar-width: none;
        // width: 100%;
        .card {
          display: flex;
          width: 30%;
          // height: 100%;
          flex-direction: column;
          align-items: center;

          gap: 16px;
          // position: relative;
          // gap: 16px;
          padding: 0px 10px 0px 10px;
          // border-radius: 16px;
          // background: var(--neutral-10, #fff);
          cursor: pointer;
          .imagesBox {
            width: 70%;
            // height: 100px;
            // display: flex;
            padding: 16px;
            // border-radius: 8px;
            border-radius: 8px;
            border: 2px solid var(--primary-stroke, #2b4d7b);
            &.imagesBoxResponsive {
              border-radius: 8px;
              border: 2px solid var(--primary-stroke, #2b4d7b);
              background: var(--primary-50, #214275);
            }

            img {
              width: 100%;
              height: 100%;
            }
          }

          .imagesBox:hover {
            border-radius: 8px;
            border: 2px solid var(--primary-stroke, #2b4d7b);
            background: var(--primary-50, #214275);
          }

          .nameBox {
            display: flex;
            align-items: center;
            justify-content: center;
            .name {
              color: var(--neutral-10, #fff);
              text-align: center;
              // font-family: Poppins;
              font-size: calc(
                10px + (14 - 10) * ((100vw - 280px) / (1600 - 280))
              );
              font-style: normal;
              font-weight: 500;
              line-height: 150%; /* 24px */
              width: 90%;
              // letter-spacing: 0.08px;
            }
          }
        }
      }
    }
  }
}

.checkDetail {
  display: flex;
  align-items: center;
  gap: 8px;
  .click {
    color: var(--neutral-10, #fff);
    text-align: center;
    // font-family: Poppins;
    font-size: calc(14px + (16 - 14) * ((100vw - 280px) / (1600 - 280)));
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
    letter-spacing: 0.08px;
  }
  img {
    width: 24px;
    height: 24px;
  }
}

.boxDropdown {
  width: 100%;
  // padding-left: 10px;
  .box {
    animation: fadeIn 2s;
    display: flex;
    flex-direction: column;
    gap: 16px;
    border-radius: 16px;
    border: 1px solid var(--primary-stroke, #2b4d7b);
    width: 100%;
    padding: 24px 24px 32px 24px;
    .nameOption {
      display: flex;
      align-items: center;
      gap: 8px;

      .titles {
        // animation: fadeIn 8s;
        color: #f7ce46;

        /* Subtitle/1 */
        // font-family: Poppins;
        font-size: calc(14px + (20 - 14) * ((100vw - 280px) / (1600 - 280)));
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
      .logoClose {
        width: 20px;
        height: 20px;
        cursor: pointer;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    .descBox {
      display: flex;
      align-items: center;
      justify-content: center;
      // animation: fadeIn 8s;
      .desc {
        color: var(--neutral-10, #fff);
        text-align: center;
        // font-family: Poppins;
        font-size: calc(10px + (16 - 10) * ((100vw - 280px) / (1600 - 280)));
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 24px */
        letter-spacing: 0.08px;
      }
    }
  }
}

.boxDropdownNone {
  display: none;
}

.responsiveOption {
  display: none;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media (max-width: 1200px) {
  .Container {
    .rightBox {
      .carouselOther {
        .otherBox {
          .card {
            .imagesBox {
              width: 100%;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1000px) {
  .Container {
    padding: 32px 24px;
    align-items: flex-start;
    flex-direction: column;

    .leftBox {
      align-items: flex-start;
      width: 100%;
    }

    .rightBox {
      display: none;
    }

    .responsiveOption {
      display: flex;
      flex-direction: column;
      gap: 16px;
      width: 100%;
      .optionBox {
        display: flex;
        flex-direction: column;
        gap: 16px;
        .carouselOther {
          display: flex;
          align-items: center;
          // justify-content: center;
          // position: relative;
          // left: 1%;
          // padding: 0p
          .otherBox::-webkit-scrollbar {
            display: none;
          }
          .otherBox {
            display: grid;
            grid-auto-flow: column;
            scroll-behavior: auto;
            gap: 16px;
            overflow-y: auto;
            overscroll-behavior-x: contain;
            scroll-snap-type: x mandatory;
            scrollbar-width: none;
            width: 100%;
            .card {
              display: flex;
              width: 115px;
              // width: 90px;
              // width: 100%;
              flex-direction: column;
              align-items: center;

              gap: 16px;
              // position: relative;
              // gap: 16px;
              padding: 0px 10px 0px 10px;
              cursor: pointer;
              .imagesBox {
                // width: 100%;
                // height: 100px;
                // display: flex;
                padding: 16px;
                // border-radius: 8px;
                border-radius: 8px;
                border: 2px solid var(--primary-stroke, #2b4d7b);
                &.imagesBoxResponsive {
                  border-radius: 8px;
                  border: 2px solid var(--primary-stroke, #2b4d7b);
                  background: var(--primary-50, #214275);
                }

                img {
                  width: 40px;
                  height: 40px;
                }
              }

              .imagesBox:hover {
                border-radius: 8px;
                border: 2px solid var(--primary-stroke, #2b4d7b);
                background: var(--primary-50, #214275);
              }

              .nameBox {
                display: flex;
                align-items: center;
                justify-content: center;
                .name {
                  color: var(--neutral-10, #fff);
                  text-align: center;
                  // font-family: Poppins;
                  font-size: calc(
                    10px + (14 - 10) * ((100vw - 280px) / (1600 - 280))
                  );
                  font-style: normal;
                  font-weight: 500;
                  line-height: 150%; /* 24px */
                  // letter-spacing: 0.08px;
                }
              }
            }
          }
        }
      }
    }
  }
}
